<template>
<span>
    <template>
        <v-layout row justify-end>
            <v-dialog v-model="dialog_foto" persistent max-width="600px">
                <v-card>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <img v-if="prod_foto_destaque" :src="`${base_url}upload/product_photo/g/${prod_foto_destaque}`" class="foto-ampliada" />
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-grey darken-1" @click="dialog_foto = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </template>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Listas de Casamento - Itens </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <h4 v-if="storegiftlistitem" class="body-1 font-weight-light">{{ storegiftlist.stor_gf_nva_nome }} / {{ storegiftlist.stor_gf_nvo_nome }}</h4>
        <v-spacer></v-spacer>
        <template>
            <v-layout row justify-end>
                <v-dialog v-model="dialog" persistent max-width="600px">
                    <template v-slot:activator="{ on }">
                        <v-btn color="blue-grey darken-2" dark class="mb-1" @click.prevent="loadStoreGiftListItemsByList">
                            <v-icon dark>sync</v-icon>Atualizar
                        </v-btn>
                        <v-btn color="light-green darken-3" dark class="mb-1 ml-2" v-on="on">Adicionar Item na Lista</v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">Adicionar Item na lista</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container grid-list-md>
                                <v-layout wrap>
                                    <v-flex xs12>
                                        <v-text-field @keyup.enter="incluirItem" v-model="itens.prod_cod_item" ref="cod_item" label="Digite o Código do Item" required outlined=""></v-text-field>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-text-field @keyup.enter="incluirItem" v-model="itens.stor_gf_it_qde" ref="stor_gf_it_qde" label="Quantidade" required outlined=""></v-text-field>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue-grey darken-1" @click="dialog = false">Fechar</v-btn>
                            <v-btn color="light-green darken-3" @click="incluirItem">Incluir</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-layout>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field xs6 v-model="search" append-icon="search" label="Localizar na Tabela" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="storegiftlistitem" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.prod_foto_destaque`]="{ item }">
                <td width="15%" v-if="item.produto.prod_foto_destaque"><img :src="`${base_url}upload/product_photo/p/${item.produto.prod_foto_destaque}?${uuid}`" class="avatar" @click.prevent="ampliarFoto(item.produto.prod_foto_destaque)"></td>
                <td width="15%" v-if="!item.produto.prod_foto_destaque"><img :src="`${base_url}upload/imagem.png`" class="avatar"></td>
            </template>

            <template v-slot:[`item.prod_referencia`]="{ item }">
                <p class="text-center pt-4">{{ item.prod_referencia }}</p>
            </template>

            <template v-slot:[`item.prod_cod_item`]="{ item }">
                <p class="text-center pt-4">{{ item.prod_cod_item }}</p>
            </template>

            <template v-slot:[`item.prod_nome`]="{ item }">
                <td>{{ item.produto.prod_nome }}</td>
            </template>

            <template v-slot:[`item.stor_gf_it_qde`]="{ item }">
                <p class="text-center pt-4">{{ item.stor_gf_it_qde }}</p>
            </template>

            <template v-slot:[`item.stor_gf_it_comprado`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.stor_gf_it_comprado" @change="onChangeEventHandlerComprado(item.stor_gf_it_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.stor_gf_it_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.stor_gf_it_status" @change="onChangeEventHandlerStatus(item.stor_gf_it_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="deleteStoreGiftList(item)" v-on="on">
                            <v-icon dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

                <v-tooltip top color="orange white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="orange lighten-1" @click.prevent="ampliarFoto(item.produto.prod_foto_destaque)" v-on="on">
                            <v-icon dark class="mdi mdi-image-search"></v-icon>
                        </v-btn>
                    </template>
                    <span>Ampliar Imagem</span>
                </v-tooltip>

            </template>

            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
            </template>
        </v-data-table>
    </v-card>
</span>
</template>

<script>
import {
    URL_BASE
} from "../../../config/configs";
import {
    uuid
} from "vue-uuid";

export default {
    name: "StoreGiftListItemComponent",
    created() {
        this.loadStoreGiftList();
        this.loadStoreGiftListItemsByList();
    },
    computed: {
        params() {
            return {
                search: ""
            };
        }
    },
    props: {
        stor_gf_id: {
            require: true
        }
    },
    data() {
        return {
            itens: {
                store_gift_list_id: this.stor_gf_id,
                prod_cod_item: "",
                stor_gf_it_qde: 1,
                stor_gf_it_comprado: 0,
                stor_gf_it_status: true
            },
            dialog: false,
            dialog_foto: false,
            prod_foto_destaque: "",
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                50,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    value: -1
                }
            ],
            headers: [{
                    sortable: false,
                    text: "Foto",
                    value: "prod_foto_destaque"
                },
                {
                    sortable: false,
                    align: "center",
                    text: "Referência",
                    value: "produto.prod_referencia"
                },
                {
                    sortable: false,
                    align: "center",
                    text: "Código Item",
                    value: "produto.prod_cod_item"
                },
                {
                    sortable: false,
                    text: "Produto",
                    value: "prod_nome"
                },
                {
                    sortable: false,
                    align: "center",
                    text: "Quantidade",
                    value: "stor_gf_it_qde"
                },
                {
                    sortable: false,
                    align: "center",
                    text: "Comprado",
                    value: "stor_gf_it_comprado"
                },
                {
                    sortable: false,
                    text: "Status",
                    value: "stor_gf_it_status"
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            storegiftlistitem: [],
            storegiftlist: [],
            base_url: URL_BASE,
            uuid: uuid.v1()
        };
    },
    methods: {
        loadStoreGiftList() {
            this.$store
                .dispatch("loadStoreGiftList", this.stor_gf_id)
                .then(response => {
                    this.storegiftlist = response;
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Erro",
                        text: "Dados não localizado",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        loadStoreGiftListItemsByList() {
            this.$store
                .dispatch("loadStoreGiftListItemsByList", this.stor_gf_id)
                .then(response => {
                    this.storegiftlistitem = response;
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Erro",
                        text: "Dados não localizado",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        incluirItem() {
            if (this.itens.prod_cod_item == "") {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: "Atenção",
                    text: "Código do produto é requerido",
                    showConfirmButton: true,
                    timer: 6000
                });
            } else {
                var cod_item = this.itens.prod_cod_item
                let prod = this.storegiftlistitem.filter(function (item) {
                    return (item.produto.prod_cod_item == cod_item);
                });
                if (prod.length >= 1) {
                    this.$swal({
                        position: "center",
                        icon: "warning",
                        title: "Atenção",
                        text: "Esse produto já existe nessa lista",
                        showConfirmButton: true,
                        timer: 6000
                    });
                } else {
                    this.$store
                        .dispatch("storeStoreGiftListItem", this.itens)
                        .then(response => {
                            if (response.success) {
                                this.loadStoreGiftListItemsByList();
                                this.itens.prod_cod_item = "";
                                this.$refs.cod_item.$el.focus();
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "warning",
                                    title: "Atenção",
                                    text: response.error,
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: "Erro",
                                text: "Opss! Algo deu errado",
                                showConfirmButton: true,
                                timer: 6000
                            });
                            this.errors = error.response;
                        });
                }

            }
        },
        ampliarFoto(foto) {
            this.prod_foto_destaque = foto;
            this.dialog_foto = true;
        },
        deleteStoreGiftList(item) {
            this.$swal({
                title: "Você tem certeza?",
                text: "Você não poderá reverter isso",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#D32F2F",
                cancelButtonColor: "#1976D2",
                confirmButtonText: "Sim, Exclua!"
            }).then(result => {
                if (result.value) {
                    this.$store
                        .dispatch("destroyStoreGiftListItem", item.stor_gf_it_id)
                        .then(response => {
                            if (response.data.success) {
                                this.$swal({
                                    position: "center",
                                    icon: "success",
                                    title: "Sucesso",
                                    text: "Dados excluídos com sucesso",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                                this.loadStoreGiftListItemsByList();
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "warning",
                                    title: "Atenção!",
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: "Opssss!",
                                text: error.data.message,
                                showConfirmButton: true,
                                timer: 6000
                            });
                        });
                }
            });
        },
        onChangeEventHandlerComprado(stor_gf_it_id, value) {
            this.$store
                .dispatch("updateStoreGiftListItemComprado", {
                    stor_gf_it_id: stor_gf_it_id,
                    stor_gf_it_comprado: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Status para comprado atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar status para comprado",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        onChangeEventHandlerStatus(stor_gf_it_id, value) {
            this.$store
                .dispatch("updateStoreGiftListItemStatus", {
                    stor_gf_it_id: stor_gf_it_id,
                    stor_gf_it_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Status do Item atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar status do Produto",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        }
    },
    components: {}
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}

.foto-ampliada {
    max-width: 100%;
}
</style>
